@import '../settings';

.modal-wrapper {
  align-items: center;
  background-color: rgba($black, .5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modal {
  background-color: $white;
  border-radius: 24px;
  height: auto;
  max-height: 80%;
  max-width: 500px;
  width: 80%;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.scroll {
  background-color: lighten($primary-color, 55%);
  height: 500px;
  overflow-y: scroll;
  padding: 1rem;
  text-align: justify;
  width: 100%;
}


@import '../settings';

.loader-wrapper {
  display: flex;
  justify-content: center;
  padding: 30%;
}

.loader {
  animation: spin 2s linear infinite;
  border: 5px solid $light-gray;
  border-radius: 50%;
  border-top: 5px solid $red;
  height: 45px;
  width: 45px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }

  100% { transform: rotate(360deg); }
}

@import '../settings';

form {

  label {
    color: $dark-gray;
    display: block;
    font-family: 'Source Sans Pro', $body-font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  [type="number"],
  [type="text"],
  [type="email"],
  [type="password"],
  [type="date"] {
    border: 1px solid $dark-beige;
    border-radius: 8px;
    margin-top: 5px;
  }

  [type="checkbox"] {
    &:checked {
      color: $red;
    }
  }

  [type="submit"] {
    margin-top: 30px;
  }

  select {
    border: 1px solid $dark-beige;
    border-radius: 8px;
    margin-top: 5px;
  }

}

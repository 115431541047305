@import '../settings';

.consent-callout-wrapper {
  background-color: $mid-beige;
  bottom: 0;
  position: absolute;
  width: inherit;
}

.consent-callout {
  background-color: $white;
  border: 1px solid $dark-beige;
  border-radius: 24px;
}

@import '../settings';

.footer {
  align-items: center;
  background-color: $dark-green;
  bottom: 0;
  display: flex;
  height: 80px;
  justify-content: center;
  position: fixed;
  text-align: center;
  width: 100%;

  .menu {
    max-width: 1024px;
    width: 1024px;
  }

  .menu-item {
    align-items: center;
    color: $light-green;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: 30px;
      padding: 5px;
      width: 30px;
    }
  }

  .menu-item-active {
    color: $off-green;
  }

}

@import '../settings';


.resource-card {
  border-bottom: 1px solid $dark-beige;
}

.form-card {
  background: $white;
  border: 1px solid lighten($light-gray, 5%);
  border-radius: 1rem;
  margin: .75rem 0;
  padding: 1rem;

  &.extra-round-borders {
    border-radius: 2rem;
  }

  &.empty {
    border: 1px dashed $light-gray;
    height: 3.5rem;
  }

  [type="number"],
  [type="text"] {
    margin-bottom: 0;
    width: 25%;
  }

  [type='checkbox'],
  [type='radio'] {
    cursor: pointer;
    font-size: 1rem;
    height: 20px;
    margin-bottom: 0;
    width: 20px;
  }

  .flex-align {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  span {
    font-size: 17px;
    margin-left: 10px;
  }

  .index {
    background-color: $success-color;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
    color: $white;
    font-family: 'Crimson Pro', $body-font-family;
    font-size: 1.7rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
  }
}

.required-field {
  &::after {
    color: $alert-color;
    content: ' *';
    font-size: 24px;
  }
}

.error {
  color: $alert-color;
  //font-size: .7rem;
  font-size: 17px;
  font-style: italic;

  &::before {
    content: '*';
  }
}

.card {
  &.heart-conditions-card {
    background: none;

    h3 {
      height: 40px;
    }

    p {
      height: 65px;
    }
  }
}

.border-right {
  border-right: 1px solid $light-gray;
}

.border-top {
  border-top: 1px solid $light-gray;
}

@import '../settings';

.add-child-wrapper {
  margin-bottom: 160px;
}

.add-child {
  padding-bottom: 160px;
}

.all-rounded-borders {
  border: 1px solid $light-gray;
  border-radius: 20px;
  box-shadow: 0 0 8px 1px $light-gray;
  padding: 1rem;
}

.add-child-callout {
  background-color: $mid-beige;
  bottom: 0;
  position: sticky;
}

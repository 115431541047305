@import '../settings';

.profile-update-form {
  .child-form-card {
    background: $white;
    border: 1px solid $dark-beige;
    border-radius: 24px;
  }
}

.bottom-aligned {
  bottom: 75px;
  position: sticky;
}

.bottom-buttons {
  background: $white;
  border: 1px solid $dark-beige;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 0 8px 1px $light-gray;
}

@import '../settings';

.opt-out-switch {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  p {
    margin-bottom: 0;
  }
}

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;

  input {
    height: 0;
    opacity: 0;
    width: 0;
  }

  .slider {
    background-color: $light-gray;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;

    &::before {
      background-color: $white;
      bottom: 4px;
      content: '';
      height: 26px;
      left: 4px;
      position: absolute;
      transition: .4s;
      width: 26px;
    }
  }
}

input:checked + .slider {
  background-color: $red;
}

input:focus + .slider {
  box-shadow: 0 0 1px $red;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}

@import '../settings';

.hero {
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: center;

  .study-callout {
    align-items: center;
    background-color: $white;
    border: 2px solid $light-green;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    padding: 10px;
    width: 200px;

    img {
      height: 20px;
      margin-right: 10px;
      width: auto;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.alert-callout {
  background: $white;
  border: 1px solid $dark-beige;
  border-radius: 24px;
  position: relative;

  .alert-img {
    left: 20px;
    position: absolute;
    top: -30px;
  }

  .alert-callout-bottom {
    align-items: center;
    background: $red;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      color: $mid-beige;
      margin-bottom: 0;
    }
  }
}

.home-bottom-padding {
  padding-bottom: 175px;
}

@import '../settings';

h1 {
  color: $black;
  font-family: 'Playfair Display', $body-font-family;
  font-weight: 700;
}

h2 {
  color: $black;
  font-family: 'Playfair Display', $body-font-family;
  font-weight: 400;
}

h3 {
  color: $black;
  font-family: 'Playfair Display', $body-font-family;
  font-weight: 700;
}

.sub-title-1 {
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 18px;
  font-weight: 600;
}

.sub-title-2 {
  font-family: 'Playfair Display', $body-font-family;
  font-size: 22px;
  font-weight: 700;
}

p {
  color: $dark-gray;
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.body-1 {
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.body-2 {
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.body-3 {
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.body-4 {
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.overline {
  color: $red;
  font-family: 'Source Sans Pro', $body-font-family;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 0;
  text-transform: uppercase;
}

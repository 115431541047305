@import './foundation_and_overrides';
@import 'global/headers';
@import 'global/environment';
@import 'global/forms';
@import 'global/spinner';
@import 'global/cards';
@import 'global/modals';
@import 'global/modals';
@import 'global/switch';
@import 'global/callouts';
@import 'global/plyr';
@import 'utilities';
@import 'pages/video_player';
@import 'pages/footer';
@import 'pages/header';
@import 'pages/home';
@import 'pages/profile';
@import 'pages/study';
@import 'global/child';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

// scss-lint:disable ImportantRule
.grecaptcha-badge {
  // version3 does not require the badge as long as links to terms of use and privacy policy are included in the form.
  display: none !important;
}

@import './settings';

@each $name, $color in $button-palette {
  .#{$name}-color {
    color: get-color(#{$name});
  }
}

.height-vh {
  height: 100vh;
}

.content-center-aligned {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-mid-beige {
  background: $mid-beige;
}

.bg-light-beige {
  background: $light-beige;
}

.bg-dark-beige {
  background: $dark-beige;
}

.bg-white {
  background: $white;
}

.green-bordered-callout {
  background-color: $white;
  border: 2px solid $light-green;
  border-radius: 24px;
  padding: 10px;
  width: 200px;
}

// scss-lint:disable ImportantRule
.img-content-center-aligned {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  justify-content: center;

  img {
    height: 20px;
    margin-right: 10px;
  }

  p {
    margin-bottom: 0;
  }
}

.sticky-consent {
  bottom: 0;
  position: absolute;
  width: inherit;
}

.absolute-bottom {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.form-card-wrapper {
  background: $white;
  border: 1px solid $dark-beige;
  border-radius: 24px;
  padding: 2rem 1rem;
}

.alert-color {
  color: $alert-color;
}

.flex-row {
  display: flex;
  flex-direction: row;

  [type='checkbox'] {
    margin-bottom: 0;
  }
}

.sshrc-consent-content {
  min-height: calc(100vh - 100px);
}

.sshrc-sticky-consent {
  bottom: 0;
  position: sticky;
  width: 100%;
}

.consent-button {
  background: $white;
  border: 1px solid $dark-beige;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.wiggle-arrow-fwd {
  animation: wiggle-fwd 1s ease-in-out infinite;
  color: $light-green;
  font-size: 100px !important;


  @keyframes wiggle-fwd {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(5deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(0deg);
    }

  }
}

.flex-pad {
  display: flex;
}

.wiggle-arrow-back {
  animation: wiggle-back 1s ease-in-out infinite;
  color: $light-green;
  display: block;
  font-size: 100px !important;
  margin-bottom: 0;
  margin-top: auto;
  position: relative;

  @keyframes wiggle-back {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(-5deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(5deg);
    }

    100% {
      transform: rotate(0deg);
    }

  }
}

.line-height-1 {
  line-height: 1;
}

@import '../settings';

.study-hero {
  min-height: 250px;
}

.custom-padding {
  padding-bottom: 80px;
}

.child-card-heading {
  align-items: center;
  display: flex;
  flex-direction: row;

  img {
    margin-right: 10px;
  }

  h2 {
    margin-right: 10px;
  }

  p {
    margin: 0;
  }
}

.child-study-dropdown {
  border-top: 1px solid $dark-beige;
}

.study-callout {
  background: $white;
  border: 1px solid $dark-beige;
  border-radius: 24px;
}

.study-callout-bottom {
  align-items: center;
  background: $red;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: $mid-beige;
    margin-bottom: 0;
  }
}

.graded-out-callout {
  background: $light-coral;
  cursor: not-allowed;
}

// scss-lint:disable SelectorDepth NestingDepth
.green-scrollable-bar {
  background: $semi-green;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .study-card {
    display: inline-block;
    height: auto;
    width: 200px;

    .content {
      align-items: center;
      border-right: 1px solid $light-green;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        color: $light-green;
      }

      h4 {
        color: $white;
      }
    }
  }
}

.completed-studies {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .study-card {
    background: $semi-green;
    border-radius: 25px;
    width: 43%;
  }

  .templeton-study-card {
    background: $semi-green;
    border-radius: 25px;
    width: 32%;
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: $light-green;
      text-align: center;
    }

    h4 {
      color: $white;
      text-align: center;
    }
  }
}

.opt-out-callout {
  background: $mid-beige;
  border: 1px solid $dark-beige;
  border-radius: 24px;
  height: 200px;

  .height-auto {
    height: inherit;
  }
}

.puppet-show-container {
  position: relative;

  .puppet-show-pause {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}

.book-page-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  padding: 0 15px;
  padding-top: 15px;
  position: relative;

  .book-page-image {
    height: 500px;
    margin-bottom: 20px;
    max-height: calc(50vh - 75px);

    img {
      height: 100%;
      width: auto;
    }
  }

  .book-page-content {
    .text-content {
      height: 20vh;
      overflow: scroll;
    }

    .text-content-long {
      height: 60vh;
      overflow: scroll;
    }
  }

  .book-page-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    button {
      background-color: $red;
      font-size: 22px;

      &:hover {
        background-color: $red;
      }
    }
  }
}

.book-thanks-container {
  height: calc(100vh - 75px);
  padding: 0 15px;
  padding-top: 15px;
}

.book-page-pause-button {
  position: absolute;
  right: 20px;
  top: 20px;
}
